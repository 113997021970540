export const HomeGrid = {
  example: [
    '欠勤時のお届提出方法を知りたい',
    '5年目以降の有休日数についての制度を知りたい',
    '福利厚生にはどのようなものがありますか？',
  ],
  capabilities: [
    '社内に共有される文書の内容を質問の形で検索できる',
    '各種の機械（重機）の取り扱い方が検索できる',
    '24時間でいつでもどこからでも利用できる',
  ],
  limitations: [
    '生成された情報は念の為、参照元の資料を確認するようにしてください',
    '個人情報を入力しないでください。チャット履歴が社内用のサーバに保存されます',
    'ボットが解凍できないことがあれば、総務に連絡してください',
  ],
};
